<template>
    <div class="change-pass">
        <div class="change-pass-wrapper">
            <h1>Change Password</h1>

            <div class="details-wrapper">
                <form @submit.prevent="submit()" class="change-pass-form">
                    <div class="input-wrapper">
                        <p class="label">Current Password</p>
                        <input v-model="current" type="password" required>
                        <p class="_error" v-if="changePassError.old_password">{{ changePassError.old_password[0] }}</p>
                        
                        <!-- <router-link to="/forgot-password">Forgot Password</router-link> -->
                    </div>

                    <div class="input-wrapper">
                        <p class="label">New Password</p>
                        <input v-model="newPass" type="password" required>
                    </div>

                    <div class="input-wrapper">
                        <p class="label">Confirm Password</p>
                        <input v-model="confirm" type="password" required>
                        <p class="_error" v-if="changePassError.password">{{ changePassError.password[0] }}</p>
                    </div>

                    <button class="cancel" :disabled="loading" @click="cancel">Cancel</button>
                    <button class="submit" type="submit" :disabled="loading">Save Changes</button>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
import { mapFields } from 'vuex-map-fields'

export default {
    data () {
        return {
            current: '',
            newPass: '',
            confirm: ''
        }
    },

    computed : {
        ...mapFields(['loading', 'changePassError'])
    },

    created () {
        this.$store.commit('SET_CHANGE_PASS_ERROR', {})
    },

    methods: {
        changePassword () {

        },

        async submit () {
            let form = new FormData

            form.append('old_password', this.current)
            form.append('password', this.newPass)
            form.append('password_confirmation', this.confirm)

            await this.$store.dispatch('changePassword', form)
        },

        cancel () {
            this.$router.go(-1)
        }
    }
}
</script>

<style lang="scss" scoped>
.change-pass {
    background: #F3F3F3;
    color: #221E1F;
    height: 100%;

    .change-pass-wrapper {
        max-width: 1200px;
        padding: 30px 20px;
        margin: auto;
        
        h1 {
            font-family: 'Crimson Pro', sans-serif;
            font-size: 34px;
            font-weight: 900;
            padding-bottom: 24px;
            border-bottom: 1px solid #B8B8B8;
        }

        .details-wrapper {
            padding: 20px 0;
            display: flex;

            .input-wrapper {
                margin-bottom: 15px;

                .label {
                    font-size: 14px;
                    margin-bottom: 10px;
                }

                input {
                    font-family: 'Crimson Pro', sans-serif;
                    width: 320px;
                    font-size: 12px;
                    padding: 10px;
                    border: 1px solid #DAD2D2;
                    border-radius: 4px;
                    resize: none;
                    margin-bottom: 5px;

                    @include desktop {
                        width: 400px;
                    }
                }

                a {
                    display: block;
                    color: #00AEEF;
                    font-size: 14px;
                    margin: 8px 0 5px;
                    cursor: pointer;
                }
            }

            button {
                width: 125px;
                font-weight: 600;
                font-size: 15px;
                padding: 12px 0;

                &:disabled {
                    opacity: 0.5;
                }

                &.cancel {
                    border: 1px solid #FF6868;
                    margin-right: 16px;
                    background: transparent;
                    color: #FF6868;
                }

                &.submit {
                    background: #00AEEF;
                    color: #ffffff;
                }
            }
        }
    }
}
</style>